<template>
  <div  class="px-15px px-lg-25px">
    <div class="aiz-titlebar text-left mt-2 mb-3">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('suo-you-shang-chuan-de-wen-jian') }}</h1>
        </div>
        <div class="col-md-6 text-md-right">
           <button type="submit" class="btn btn-primary">{{ $t('shang-chuan-xin-wen-jian') }}</button>
        </div>
      </div>
    </div>

    
    <div class="card">
      <form id="sort_uploads" >
        <div class="card-header row gutters-5">
          <div class="col-md-3">
            <h5 class="mb-0 h6">{{ $t('suo-you-wen-jian') }}</h5>
          </div>
          <div class="col-md-3 ml-auto mr-0">
            <a-select class="form-control form-control-xs clear" size="large" >
              <a-select-option v-for="(item, i) in sortList" :key="i" :value="item.vaule">{{item.label}}</a-select-option>
            </a-select>
          </div>
          <div class="col-md-3">
            <input type="text" class="form-control form-control-xs" name="search" :placeholder="$t('sou-suo-nin-de-wen-jian')" >
          </div>
          <div class="col-auto">
            <button type="submit" class="btn btn-primary">{{ $t('sou-suo') }}</button>
          </div>
        </div>
      </form>
      <div class="card-body">
        <div class="row gutters-5">
          <div class="col-auto w-140px w-lg-220px" v-for="(item, i) in fileList" :key="i">
            <div class="aiz-file-box">
              <div class="dropdown-file" >
                <Popover v-model="item.showAction" trigger="click">
                  <div class="">
                    <a href="javascript:void(0)"  class="dropdown-item" @click="fileDetail(item, i)">
                      <i class="las la-info-circle mr-2"></i>
                      <span>{{ $t('xiang-xi-xin-xi') }}</span>
                    </a>
                    <a class="dropdown-item" >
                      <i class="la la-download mr-2"></i>
                      <span>{{ $t('xia-zai') }}</span>
                    </a>
                    <a href="javascript:void(0)"  class="dropdown-item">
                      <i class="las la-clipboard mr-2"></i>
                      <span>{{ $t('fu-zhi-lian-jie') }}</span>
                    </a>
                    <a href="javascript:void(0)" class="dropdown-item" data-target="#delete-modal">
                      <i class="las la-trash mr-2"></i>
                      <span>{{ $t('shan-chu') }}</span>
                    </a>
                  </div>
                  <template #reference>
                    
                      <a class="dropdown-link" data-toggle="dropdown">
                        <i class="la la-ellipsis-v"></i>
                      </a>
                  </template>
                </Popover>
              </div>
              <div class="card card-file aiz-uploader-select c-default" :title="$t('wen-jian-ming')">
                <div class="card-file-thumb">
                  <img :src="item.url" class="img-fit">
                  <!-- <i class="las la-file-video"></i>
                  <i class="las la-file"></i> -->
                </div>
                <div class="card-body">
                  <h6 class="d-flex">
                    <span class="text-truncate title">{{ item.name }}</span>
                    <span class="ext">.{{item.type }}</span>
                  </h6>
                  <p>{{ item.size }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="aiz-pagination mt-3">
          <pageBar :totalPage="56" :page="51"></pageBar>
        </div>
      </div>
    </div>

    <Popup v-model="showPopup" position="right">
      <div class="popup-box small " id="wallet_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="full-height modal-dialog-right" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('wen-jian-xin-xi') }}</h5>
              <button type="button" class="close" @click="cancel"></button>
            </div>
            <div class="modal-body c-scrollbar-light position-relative" id="info-modal-content">
              <div class="c-preloader text-center absolute-center" v-show="loading">
                <i class="las la-spinner la-spin la-3x opacity-70"></i>
              </div>
              <div v-show="!loading">
                <div class="form-group">
                  <label>{{ $t('wen-jian-ming-0') }}</label>
                  <input type="text" class="form-control"  disabled>
                </div>
                <div class="form-group">
                  <label>{{ $t('wen-jian-lei-xing') }}</label>
                  <input type="text" class="form-control"  disabled>
                </div>
                <div class="form-group">
                  <label>{{ $t('wen-jian-da-xiao') }}</label>
                  <input type="text" class="form-control"  disabled>
                </div>
                <div class="form-group">
                  <label>{{ $t('shang-chuan-shi-jian') }}</label>
                  <input type="text" class="form-control"  disabled>
                </div>
                <div class="form-group text-center">
                  <a class="btn btn-secondary" >{{ $t('xia-zai-0') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popover, Popup } from 'vant'
import pageBar from '@/components/page'
export default {
  components: {
    pageBar,
    Popover,
    Popup
  },
  data() {
    return {
      showPopup: false,
      loading: false,
      sortList: [
        {
          value: 1,
          label: this.$t('an-zui-xin-pai-xu')
        },
        {
          value: 2,
          label: this.$t('an-zui-jiu-pai-xu')
        },
        {
          value: 3,
          label: this.$t('an-zui-da-pai-xu')
        },
        {
          value: 4,
          label: this.$t('an-zui-xiao-pai-xu')
        },
      ],
      fileList: [
        {
          url: require('../../../assets/imgs/avatar-place.png'),
          name: '111',
          type: 'png',
          size: '101KB'
        },
        {
          url: require('../../../assets/imgs/avatar-place.png'),
          name: '111',
          type: 'png',
          size: '101KB'
        },
        {
          url: require('../../../assets/imgs/avatar-place.png'),
          name: '111',
          type: 'png',
          size: '101KB'
        },
      ]
    }
  },
  methods: {
    cancel() {
      this.showPopup = false
    },
    fileDetail(data, i) {
      this.$set(this.fileList[i], 'showAction', false)
      this.showPopup = true
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1500)
    }
  }
}
</script>